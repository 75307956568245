// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getTeacherNameReverse,
  getUserDetails,
  handleTokenError,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  isFirstSectionOpen: boolean;
  isSecondSectionOpen: boolean;
  selectedState: string,
  selectedDistrict: string,
  isnumber:number,
  selectedSchool:string,
  showSchoolDropdown: boolean,
  isSchoolConfirmed:boolean,
  statesList: Array<{ id: string; name: string }>;
  districtsList: Array<{ id: string; name: string }>;
  schoolsList: Array<{ id: string; name: string }>;
  firstName: string,
  lastName: string,
  email: string,
  firstNameError: boolean;
  lastNameError: boolean;
  emailError: boolean;
  selectedSchoolName:string,
  showOnlyClassboxSchools: boolean,
  // Customizable Area Esnd
}

interface SS {
  id: any;
}

export interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
}
// Customizable Area Start
export default class InviteTeacherController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitApiCallId: any;
  teacherSelfDataApiCallId:string="";
  stateDataApiCallId:string = "";
  districtDataApiCallId:string = "";
  schoolDataApiCallId:string = "";
  addAdditionalDetailApiCallId: any;
  updateUserStatus: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      selectedState: "",
      selectedDistrict: "",
      isnumber:1,
      isFirstSectionOpen: true,
      isSecondSectionOpen: false,
      selectedSchool:"",
      showSchoolDropdown: true,
      isSchoolConfirmed: false,
      statesList: [],
      districtsList: [],
      schoolsList: [],
      firstName: '',
      lastName: '',
      email: '',
      selectedSchoolName:"",
      showOnlyClassboxSchools: true,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    await this.stateDataApi();
    const selectedSchoolName = localStorage.getItem('selectedSchoolName');
    this.setState({ selectedSchoolName: selectedSchoolName || '' });
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    if (!prevState.selectedState || !prevState.selectedDistrict) {
      if (this.state.selectedState && this.state.selectedDistrict) {
        setTimeout(() => {
          this.setState({
            isFirstSectionOpen: false,
            isSecondSectionOpen: true,
            isnumber: 2
          });
        }, 500);
        this.schoolDataApi(this.state.selectedDistrict);
      }
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson && responseJson.errors && apiRequestCallId === this.teacherSelfDataApiCallId) {
        const errors = responseJson.errors;
        if (errors.some((error: any) => error.account && error.account.includes("email address must end in"))) {
          this.setState({ 
            emailError: true,
          });
          return;
        }
      }
      if (responseJson && !responseJson.errors) 
        {
          if(apiRequestCallId === this.stateDataApiCallId){
            const stateData = responseJson.data;
            this.setState({
              statesList: stateData.map((state: any) => ({
                id: state.id,
                name: state.attributes.name
              }))
            });
          }
          if(apiRequestCallId === this.districtDataApiCallId){
            const districtData = responseJson.data;
            this.setState({
              districtsList: districtData.map((district: any) => ({
                id: district.id,
                name: district.attributes.name
              }))
            });
          }
          if(apiRequestCallId === this.schoolDataApiCallId){
            const schoolData = responseJson.data;
            this.setState({
              schoolsList: schoolData.map((school: any) => ({
                id: school.id,
                name: school.attributes.name,
              })),
            });
          }
          if(apiRequestCallId === this.teacherSelfDataApiCallId){
            const teacherData = responseJson; 
            if(teacherData){
              await removeStorageData('selectedschoolId')
              this.handleRequest();
            }
          }
        }
    }
    // Customizable Area End
  }

    apiCall = async (apiData: ApiCallInterface) => {
      const token = await getStorageData("authToken");
      const { contentType, method, endPoint , body} = apiData;
      const header = {
        "Content-Type": contentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    };
    stateDataApi= async()=>{
      const stateEndPoint = configJSON.stateEndPoint
      this.stateDataApiCallId = await this.apiCall({
        contentType: configJSON.stateDataContentType,
        method: configJSON.stateDataMethod,
        endPoint: stateEndPoint
      });
    }
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      let isValid = true;
      if (!this.state.email.trim()) {
        this.setState({ emailError: true });
      }
      if (!this.state.firstName.trim()) {
        this.setState({ firstNameError: true });
        isValid = false;
      } else {
        this.setState({ firstNameError: false });
      }
  
      if (!this.state.lastName.trim()) {
        this.setState({ lastNameError: true });
        isValid = false;
      } else {
        this.setState({ lastNameError: false });
      }
      if (isValid) {
        this.teacherSelfDataApi();
      }
    };
    
    districtDataApi = async (stateId: string) => {
      const districtEndPoint = `${configJSON.districtEndPoint}?state_id=${stateId}`;
      this.districtDataApiCallId = await this.apiCall({
        contentType: configJSON.districtDataContentType,
        method: configJSON.districtDataMethod,
        endPoint: districtEndPoint
      });
    };
    schoolDataApi = async (districtId: string) => {
      const schoolEndPoint = `${configJSON.schoolEndPoint}?district_id=${districtId}`;
      this.schoolDataApiCallId = await this.apiCall({
        contentType: configJSON.schoolDataContentType,
        method: configJSON.schoolDataMethod,
        endPoint: schoolEndPoint,
      });
    };
    teacherSelfDataApi = async () => {
      const teacherSelfEndPoint = configJSON.teacherSelfEndPoint
      const selectedSchoolId =localStorage.getItem('selectedschoolId');
      const bodyData = {
        data: {
          type: "email_account",
          attributes: {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            role_name: "Teacher",
            email: this.state.email,
            admin_create: false,
            school_id: selectedSchoolId,
          }
        }
    };
    
      this.teacherSelfDataApiCallId = await this.apiCall({
        contentType: configJSON.teacherSelfDataContentType,
        method: configJSON.teacherSelfDataMethod,
        endPoint: teacherSelfEndPoint,
        body: bodyData,
      });
    };
    handleNavigation = () => {
      localStorage.setItem('selectedSchoolName', this.state.selectedSchoolName);
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), "TeacherSelf");
      runEngine.sendMessage(message.id, message);
    }
    handleRequest = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), "RequestSelf");
      runEngine.sendMessage(message.id, message);
    }
    handleLogin = () => {
      localStorage.removeItem('selectedSchoolName');
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
      runEngine.sendMessage(message.id, message);
    }
    isFirstSectionComplete = () => {
      return this.state.selectedState !== "" && this.state.selectedDistrict !== "";
    };
    isSecondSectionComplete = () => {
      return this.state.selectedSchool !== "";
    };
    toggleSection = (section: 'first' | 'second') => {
      if (section === 'first') {
        this.setState({ isFirstSectionOpen: !this.state.isFirstSectionOpen });
        this.setState({ isnumber: 1 });
      } else {
        if (this.isFirstSectionComplete()) {
          this.setState({ isSecondSectionOpen: !this.state.isSecondSectionOpen });
          this.setState({ isnumber: 2 });
        }
      }
    };
    handleStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const stateId = event.target.value as string;
      this.setState({ 
        selectedState: stateId,
        selectedDistrict: "",
        districtsList: []
      }, () => {
        if (stateId) {
          this.districtDataApi(stateId);
        }
      });
    };
    handleDistrictChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const districtId = event.target.value as string;
      this.setState(
        {
          selectedDistrict: districtId,
          selectedSchool: "",
          schoolsList: [],
        },
        () => {
          if (districtId) {
            this.schoolDataApi(districtId);
          }
        }
      );
    };
    isBothSectionsComplete = () => {
      return this.isFirstSectionComplete() && this.state.isSchoolConfirmed;
    };
    handleSchoolChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const schoolId = event.target.value;
      const selectedSchool = this.state.schoolsList.find((school) => school.id === schoolId);
      this.setState({ 
        selectedSchool: schoolId,
        selectedSchoolName: selectedSchool ? selectedSchool.name : "",
        isSchoolConfirmed: false
      });
    }
    handleConfirmSchool = () => {
      if (this.isSecondSectionComplete()) {
        const schoolId = this.state.selectedSchool;
        localStorage.setItem('selectedschoolId', schoolId);
        this.setState({ isSchoolConfirmed: true });
        localStorage.setItem('selectedSchoolName', this.state.selectedSchoolName);
      }
    }
}

// Customizable Area End

// Customizable Area End
